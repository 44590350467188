import { useState } from 'react';

const useContainer = () => {
  const [isDrawerOpened, setDrawerOpenCondition] = useState(false);

  /**
   * Toggle drawer
   */
  const toggleDrawer = () => setDrawerOpenCondition((prev) => !prev);

  return {
    isDrawerOpened,
    toggleDrawer,
  };
};

export default useContainer;
