import { useRouter } from 'next/router';
import { useCallback } from 'react';

type UseAppRoutingConfig = {
  method?: string;
};

const useAppRouting = () => {
  const router = useRouter();

  /**
   * Go to login
   */
  const goToPage = useCallback(
    (route: string, config?: UseAppRoutingConfig, ...rest: any) =>
      () => {
        const method = config?.method || 'push';

        (router as any)[method](route, ...rest);
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router],
  );

  return {
    goToPage,
  };
};

export default useAppRouting;
