import { Button, Divider, Drawer, Space } from 'antd';
import classNames from 'classnames';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'constants/routes';

import useContainer from './hook';
import type { StaticHeaderMenuProps } from './types';

function StaticHeaderMenu({ activeItem, onLogin, onRegister }: StaticHeaderMenuProps) {
  const { isDrawerOpened, toggleDrawer } = useContainer();

  return (
    <>
      <Button
        icon={<i className="icon icon-menu" />}
        type={'control' as any}
        onClick={toggleDrawer}
        aria-label="Menu"
      />
      <Drawer
        className="ant-drawer-static"
        visible={isDrawerOpened}
        closeIcon={<i className="icon icon-cross fs-16" />}
        placement="left"
        width={295}
        onClose={toggleDrawer}
      >
        <Space className="w-100" size={8} direction="vertical">
          <Link href={ROUTES.ABOUT_US.PATH}>
            <a
              className={classNames('static-menu__item', {
                'static-menu__item--active': ROUTES.ABOUT_US.KEY === activeItem,
              })}
            >
              <FormattedMessage id="shared.aboutUs" />
            </a>
          </Link>
          <Link href={ROUTES.WORK_WITH_US.PATH}>
            <a
              className={classNames('static-menu__item', {
                'static-menu__item--active': ROUTES.WORK_WITH_US.KEY === activeItem,
              })}
            >
              <FormattedMessage id="shared.workWithUs" />
            </a>
          </Link>
          <Link href={ROUTES.CONTACT_US.PATH}>
            <a
              className={classNames('static-menu__item', {
                'static-menu__item--active': ROUTES.CONTACT_US.KEY === activeItem,
              })}
            >
              <FormattedMessage id="shared.contactUs" />
            </a>
          </Link>
        </Space>
        <div className="px-12">
          <Divider />
          <Button className="mb-24" type={'tertiary' as any} onClick={onLogin} block>
            <i className="icon icon-user mr-4" />
            <FormattedMessage id="shared.logIn" />
          </Button>
          <Button type="primary" onClick={onRegister} block>
            <FormattedMessage id="shared.registerNow" />
          </Button>
        </div>
      </Drawer>
    </>
  );
}

export default StaticHeaderMenu;
