import { Col, Row, Space, Divider } from 'antd';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { APP_NAME, SUPPORT_EMAIL } from 'constants/app';
import ROUTES from 'constants/routes';

import useContainer from './hook';

function StaticFooter() {
  const { socialLinks } = useContainer();

  return (
    <footer className="main-footer">
      <div className="container-lg">
        <div className="d-none d-xl-block py-40">
          <Row gutter={24}>
            <Col span={4}>
              <p className="text-headline fw-600 in-primary">
                <Link href={ROUTES.HOME.PATH}>{APP_NAME}</Link>
              </p>
            </Col>
            <Col span={4}>
              <p className="text-subheader in-black mb-16">
                <FormattedMessage id="shared.info" />
              </p>
              <Space className="w-100" direction="vertical" size={16}>
                <Link href={ROUTES.TERMS_OF_USE.PATH}>
                  <a className="main-link text-subheader in-dark">
                    <FormattedMessage id="shared.termsOfUse" />
                  </a>
                </Link>
                <Link href={ROUTES.PRIVACY_POLICY.PATH}>
                  <a className="main-link text-subheader in-dark">
                    <FormattedMessage id="shared.privacyPolicy" />
                  </a>
                </Link>
                <Link href={ROUTES.HIPAA_AND_PHI.PATH}>
                  <a className="main-link text-subheader in-dark">
                    <FormattedMessage id="shared.hipaaAndPhi" />
                  </a>
                </Link>
              </Space>
            </Col>
            <Col span={4}>
              <p className="text-subheader in-black mb-16">{APP_NAME}</p>
              <Space className="w-100" direction="vertical" size={16}>
                <Link href={ROUTES.ABOUT_US.PATH}>
                  <a className="main-link text-subheader in-dark">
                    <FormattedMessage id="shared.aboutUs" />
                  </a>
                </Link>
                <Link href={ROUTES.CONTACT_US.PATH}>
                  <a className="main-link text-subheader in-dark">
                    <FormattedMessage id="shared.contactUs" />
                  </a>
                </Link>
              </Space>
            </Col>
            <Col span={4}>
              <p className="text-subheader text-uppercase in-black mb-16">
                <FormattedMessage id="shared.support" />
              </p>
              <Space className="w-100" direction="vertical" size={16}>
                <Link href={ROUTES.FAQ.PATH}>
                  <a className="main-link text-subheader in-dark">
                    <FormattedMessage id="shared.faq" />
                  </a>
                </Link>
                <Link href={`mailto:${SUPPORT_EMAIL}`}>
                  <a className="main-link text-subheader in-dark">{SUPPORT_EMAIL}</a>
                </Link>
              </Space>
            </Col>
          </Row>
        </div>
        <Divider className="d-none d-xl-block mt-0 mb-0" />
        <div className="d-flex flex-column flex-md-row align-items-md-center py-xl-40">
          <p className="text-caption text-xl-body order-2 order-md-1 in-black mt-16 mt-md-0">
            <FormattedMessage id="shared.copyright" />
          </p>
          <div className="order-1 order-md-2 flex-center-y flex-1">
            <Divider className="d-none d-md-block d-xl-none h-24 in-secondary-300 ml-16 mr-16" type="vertical" />
            <Link href={ROUTES.PRIVACY_POLICY.PATH}>
              <a className="d-xl-none main-link text-caption in-black">
                <FormattedMessage id="shared.privacyPolicy" />
              </a>
            </Link>
            <Divider className="d-xl-none h-24 in-secondary-300 ml-12 ml-md-16 mr-12 mr-md-16" type="vertical" />
            <Link href={ROUTES.TERMS_OF_USE.PATH}>
              <a className="d-xl-none main-link text-caption in-black">
                <FormattedMessage id="shared.termsOfUse" />
              </a>
            </Link>
            <div className="flex-center-y ml-auto">
              <Link href={socialLinks.facebook}>
                <a className="main-footer__social-link" aria-label="Facebook">
                  <i className="icon icon-facebook" />
                </a>
              </Link>
              <Link href={socialLinks.twitter}>
                <a className="main-footer__social-link" aria-label="Twitter">
                  <i className="icon icon-twitter" />
                </a>
              </Link>
              <Link href={socialLinks.instagram}>
                <a className="main-footer__social-link" aria-label="Instagram">
                  <i className="icon icon-instagram" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default StaticFooter;
