import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useDispatchAction from 'hooks/useDispatchAction';
import { fetchSocialLinkList } from 'state/app/actions';
import { socialLinksListSelector } from 'state/app/selectors';
import type { SocialLinksProps } from 'state/app/types';

const useContainer = () => {
  const fetchSocialLinkListAction = useDispatchAction(fetchSocialLinkList);
  const socialLinks = useSelector(socialLinksListSelector) as SocialLinksProps;

  useEffect(() => {
    fetchSocialLinkListAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    socialLinks,
  };
};

export default useContainer;
