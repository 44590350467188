import ROUTES from 'constants/routes';
import useAppRouting from 'hooks/useAppRouting';
import StaticFooter from 'views/shared/StaticFooter';
import StaticHeader from 'views/shared/StaticHeader';

import type { StaticLayoutProps } from './types';

function StaticLayout({ activeItem, children }: StaticLayoutProps) {
  const { goToPage } = useAppRouting();

  return (
    <div className="main-wrap d-flex flex-column bg-white">
      <StaticHeader
        activeItem={activeItem}
        onLogin={goToPage(ROUTES.LOGIN.PATH)}
        onRegister={goToPage(ROUTES.REGISTRATION.PATH)}
      />
      {children}
      <StaticFooter />
    </div>
  );
}

export default StaticLayout;
