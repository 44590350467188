import { Button } from 'antd';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'constants/routes';

import StaticHeaderMenu from './Menu';
import type { StaticHeaderProps } from './types';

function StaticHeader({ activeItem, onLogin, onRegister }: StaticHeaderProps) {
  return (
    <header className="header-static">
      <div className="flex-center-y justify-content-between container-lg">
        <div className="flex-center-y">
          <div className="d-xl-none mr-24">
            <StaticHeaderMenu activeItem={activeItem} onLogin={onLogin} onRegister={onRegister} />
          </div>
          <Link href={ROUTES.HOME.PATH}>
            <a>
              <Image src="/images/logo_dark.svg" width={100} height={38} alt="logo" />
            </a>
          </Link>
        </div>
        <div className="d-none d-xl-flex align-items-center">
          <ul className="flex-center-y mr-56">
            <li
              className={classNames('static-nav__item', {
                'static-nav__item--active': ROUTES.ABOUT_US.KEY === activeItem,
              })}
            >
              <Link href={ROUTES.ABOUT_US.PATH}>
                <a>
                  <FormattedMessage id="shared.aboutUs" />
                </a>
              </Link>
            </li>
            <li
              className={classNames('static-nav__item', {
                'static-nav__item--active': ROUTES.WORK_WITH_US.KEY === activeItem,
              })}
            >
              <Link href={ROUTES.WORK_WITH_US.PATH}>
                <a>
                  <FormattedMessage id="shared.workWithUs" />
                </a>
              </Link>
            </li>
            <li
              className={classNames('static-nav__item', {
                'static-nav__item--active': ROUTES.CONTACT_US.KEY === activeItem,
              })}
            >
              <Link href={ROUTES.CONTACT_US.PATH}>
                <a>
                  <FormattedMessage id="shared.contactUs" />
                </a>
              </Link>
            </li>
          </ul>
          <Button className="mr-24" type={'tertiary' as any} onClick={onLogin}>
            <i className="icon icon-user mr-4" />
            <FormattedMessage id="shared.logIn" />
          </Button>
          <Button className="mr-24" type="primary" onClick={onRegister}>
            <FormattedMessage id="shared.registerNow" />
          </Button>
        </div>
      </div>
    </header>
  );
}

export default StaticHeader;
